import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, ResponsiveEmbed } from "react-bootstrap"
import { FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';


const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Container>
      <Row>
        <Col>
          <div className='prolacs-divider-title'>
            <br></br>
            <br></br>
            <h1>Contact Us</h1>
          </div>
          <Row>
            <Col sm>
              <p>Ask us about our solutions, get information about our products. We enjoy our work and doing what is necessary.
You can reach us from the contact information below.</p>
            </Col>
          </Row>
          <Row>
            <Col sm >
              <FaMapMarkerAlt style={{ fontSize: '20px', }} /> Location
              <br></br>
              A.O.S.B. 10007 St. N:11 <br></br>
              Administrative Building Floor: 01<br></br>
              Çiğli, İZMİR / TURKEY<br></br>
              Post Code: 35620<br></br>
              <br />
            </Col>
            <Col sm>
              <a href="https://linkedin.com/company/prolacs"><FaLinkedin style={{ fontSize: '20px' }} /> Prolacs </a>
              <br></br>
              <a href="mailto:info@prolacs.com"><FaEnvelope style={{ fontSize: '20px' }} /> info@prolacs.com </a>
              <br></br>
              <a href="tel:+902163921642"><FaPhone style={{ fontSize: '20px' }} /> +90 (216) 392 16 42 </a>
            </Col>
          </Row>
        </Col>
        <Col sm className='contact-us-col-2'>
          <div className='prolacs-about-us-row' style={{ width: 'auto', height: 'auto' }}>
            <ResponsiveEmbed aspect="a16by9">
              <embed src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12491.556965395408!2d27.0341538!3d38.4901976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x620abbcacc1a496d!2sProlacs!5e0!3m2!1str!2str!4v1562675414933!5m2!1str!2str" />
            </ResponsiveEmbed>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout >
)

export default Contact
